const en = {
  nav: {
    login: "Login",
    home: "Home",
    admins: "Admins",
    events: "Events",
    documents: "Documents",
    visitors: "Visitors",
    partners: "Partners",
    duk: "DUK",
    information: "Information",
    catering: "Feeding",
    banners: "Banners",
  },
  descriptions: {
    welcomeToTwinsbet: "Welcome to TWINSBET arena",
    useTwinsBet:
      "Use TWINSBET arena opportunities and enjoy the event even more.",
    // app: "MOBILE APP",
    app: "FOOD ORDERING", // TEMP
    casino: "CASINO",
    // useTheApp: "Use the arena app",
    useTheApp: "Food and drinks menu", // TEMP
    // allFunctions: "All arena functions in one place",
    allFunctions: "Order conveniently and quickly", // TEMP
    findLuck: "Gambling and slot machines",
    twinsBet: "www.twinsbet.lt",
  },
};

export default en;
